// @flow
import * as actions from '../../actions/cinemaAudience/audienceByTopTenMovieByDate';

import type { AudienceByTopTenMovieByDateActions } from '../../actions/cinemaAudience/audienceByTopTenMovieByDate';

type State = {
  isFetching: boolean,
  audienceByTopTenMovieByDateResults: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  audienceByTopTenMovieByDateResults: [],
  message: ""
};

export const audienceByTopTenMovieByDate = (
  state: State = intialState,
  action: AudienceByTopTenMovieByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByTopTenMovieByDateResults: [],
        message: ""
      }
    case actions.LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_SUCCESS:
      return {
        ...state,
        audienceByTopTenMovieByDateResults: action.payload.audience_by_movie_top_ten,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
