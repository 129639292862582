// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl,sortByKey } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_GENRES_REQUEST = 'LOAD_GENRES_REQUEST';
export const LOAD_GENRES_SUCCESS = 'LOAD_GENRES_SUCCESS';
export const LOAD_GENRES_FAILURE = 'LOAD_GENRES_FAILURE';

type LoadGenresRequest = {
  type: typeof LOAD_GENRES_REQUEST,
};

const loadGenresRequest = (): loadGenresRequest => {
  return {
    type: LOAD_GENRES_REQUEST,
  };
};

type LoadGenresSuccess = {
  type: typeof LOAD_GENRES_SUCCESS,
  payload: Object,
};

const loadGenresSuccess = (genres: Object): loadGenresSuccess => {
  return {
    type: LOAD_GENRES_SUCCESS,
    payload: sortByKey(genres, 'genreDesc'), //sort ascending by title before returning
  };
};

type LoadGenresFailure = {
  type: typeof LOAD_GENRES_FAILURE,
  error: string,
};

const loadGenresFailure = (error: string): loadGenresFailure => {
  return {
    type: LOAD_GENRES_FAILURE,
    error,
  };
};

export type AnnouncementsActions =
  | LoadGenresRequest
  | LoadGenresSuccess
  | LoadGenresFailure;

export const loadGenres = (): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadGenresRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/genres`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadGenresSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadGenresFailure(error));
    return error;
  }
};
