// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_REQUEST = 'LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_REQUEST';
export const LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_SUCCESS = 'LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_SUCCESS';
export const LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_FAILURE = 'LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_FAILURE';

type LoadTotalAudienceStatByDateRequest = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_REQUEST,
};

const loadTotalAudienceStatByDateRequest = (): loadTotalAudienceStatByDateRequest => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_REQUEST,
  };
};

type LoadTotalAudienceStatByDateSuccess = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_SUCCESS,
  payload: Object,
};

const loadTotalAudienceStatByDateSuccess = (audience: Object): loadTotalAudienceStatByDateSuccess => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_SUCCESS,
    payload: audience, //sort ascending by title before returning
  };
};

type LoadTotalAudienceStatByDateFailure = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_FAILURE,
  error: string,
};

const loadTotalAudienceStatByDateFailure = (error: string): loadTotalAudienceStatByDateFailure => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_FAILURE,
    error,
  };
};

export type TotalAudienceStatByDateActions =
  | LoadTotalAudienceStatByDateRequest
  | LoadTotalAudienceStatByDateSuccess
  | LoadTotalAudienceStatByDateFailure;

export const loadTotalAudienceStatByDate = (date_from: string,date_to: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadTotalAudienceStatByDateRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/total-cinema-delivery?date_from=${date_from}&date_to=${date_to}${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadTotalAudienceStatByDateSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadTotalAudienceStatByDateFailure(error));
    return error;
  }
};
