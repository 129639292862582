// @flow
import * as actions from '../../actions/cinemaAudience/ftaTvConsumptionByDate';

import type { FtaTvConsumptionByDateActions } from '../../actions/cinemaAudience/ftaTvConsumptionByDate';

type State = {
  isFetching: boolean,
  ftaTvConsumptionByDateResults: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  ftaTvConsumptionByDateResults: [],
  message: ""
};

export const ftaTvConsumptionByDate = (
  state: State = intialState,
  action: FtaTvConsumptionByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        ftaTvConsumptionByDateResults: []
      }
    case actions.LOAD_FTA_TV_CONSUMPTION_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FTA_TV_CONSUMPTION_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FTA_TV_CONSUMPTION_BY_DATE_SUCCESS:
      return {
        ...state,
        ftaTvConsumptionByDateResults: action.payload.fta_tv_consumption,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
