// @flow
import * as React from 'react';
import { Grid} from 'semantic-ui-react';

const Footer = () => (
  <div className="footer">
      <Grid columns='equal' className="dashboard-grid">
        <Grid.Row>
          <Grid.Column textAlign="left">
            <p>Copyright - 2024 Val Morgan Cinema Network</p>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <p>Powered by CineTAM fused with Roy Morgan Single Source</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
  </div>
);

export default Footer;


