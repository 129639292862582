// @flow
import * as actions from '../../actions/campaignByAudience/audienceRfStatForCampaign';

import type { AudienceRfStatForCampaignActions } from '../../actions/campaignByAudience/audienceRfStatForCampaign';

type State = {
  isFetching: boolean,
  value: string,
  message: String,
};

const initialState = {
  isFetching: false,
  value: "",
  message: ""
};

export const audienceRfStatForCampaign = (
  state: State = initialState,
  action: AudienceRfStatForCampaignActions
) => {
  switch (action.type) {
    case 'RESET_CAMPAIGN_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        value: ''
      };
    case actions.LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
