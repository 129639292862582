// @flow
type Props = {
  method: string,
  endpoint: string,
  body?: Object,
  customHeaders?: Object,
  headers?: Object
};

type APIError = Error & {
  content?: Object,
  code?: string,
  msg?: string,
};

/*
 * DO NOT MODIFY THIS METHOD
 *
 * API logic should not live inside this method
 * unless it's going to be used across multiple API calls.
 *
 * Please speak to Sean Gilmore if you have issues with this.
 */
export const callAPI = async ({
  method,
  targetUrl,
  headers,
  body,
}: Props): Promise<any> => {
    const fullHeaders = new Headers({
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          ...headers
        });

    // $FlowFixMe
    const response = await fetch(targetUrl, {
      method,
      headers: fullHeaders,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const error: APIError = Error('Invalid API Response');
      error.msg = errorResponse.errors
        ? errorResponse.errors[0].message
        : errorResponse.statusMessage
        ? errorResponse.statusMessage
        : 'API Error';

      throw error;
    }
    const json = await response.json();

    return json;
};
