// @flow
import type { ThunkAction, Dispatch, Action } from '../../types';

export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';

const setSearchStringAction = (searchString: string): Action => {
  return {
    type: SET_SEARCH_STRING,
    payload: searchString,
  };
};

export const setSearchString = (searchString: string): ThunkAction => async (
  dispatch: Dispatch
) => {
  dispatch(setSearchStringAction(searchString));
};
