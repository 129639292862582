// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl,sortByKey } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_CAMPAIGNS_REQUEST = 'LOAD_CAMPAIGNS_REQUEST';
export const LOAD_CAMPAIGNS_SUCCESS = 'LOAD_CAMPAIGNS_SUCCESS';
export const LOAD_CAMPAIGNS_FAILURE = 'LOAD_CAMPAIGNS_FAILURE';

type LoadCampaignsRequest = {
  type: typeof LOAD_CAMPAIGNS_REQUEST,
};

const loadCampaignsRequest = (): loadCampaignsRequest => {
  return {
    type: LOAD_CAMPAIGNS_REQUEST,
  };
};

type LoadCampaignsSuccess = {
  type: typeof LOAD_CAMPAIGNS_SUCCESS,
  payload: Object,
};

const loadCampaignsSuccess = (campaigns: Object): loadCampaignsSuccess => {
  return {
    type: LOAD_CAMPAIGNS_SUCCESS,
    payload: sortByKey(campaigns, 'campaign_no'), //sort ascending by title before returning
  };
};

type LoadCampaignsFailure = {
  type: typeof LOAD_CAMPAIGNS_FAILURE,
  error: string,
};

const loadCampaignsFailure = (error: string): loadCampaignsFailure => {
  return {
    type: LOAD_CAMPAIGNS_FAILURE,
    error,
  };
};

export type CampaignActions =
  | LoadCampaignsRequest
  | LoadCampaignsSuccess
  | LoadCampaignsFailure;

export const loadCampaigns = (): ThunkwithAction<CampaignActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadCampaignsRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/campaigns`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadCampaignsSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadCampaignsFailure(error));
    return error;
  }
};
