// @flow
import * as actions from '../../actions/cinemaAudience/uniqueAudienceStatByDate';

import type { UniqueAudienceStatByDateActions } from '../../actions/cinemaAudience/uniqueAudienceStatByDate';

type State = {
  isFetching: boolean,
  uniqueAudienceStatByDateResults: string,
  message: string,
};

const intialState = {
  isFetching: false,
  uniqueAudienceStatByDateResults: "",
  message: "",
};

export const uniqueAudienceStatByDate = (
  state: State = intialState,
  action: UniqueAudienceStatByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        uniqueAudienceStatByDateResults: "",
        message: ""
      }
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_SUCCESS:
      return {
        ...state,
        uniqueAudienceStatByDateResults: action.payload.unique_cinema_delivery,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
