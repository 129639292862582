// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_REQUEST = 'LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_REQUEST';
export const LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_SUCCESS = 'LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_SUCCESS';
export const LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_FAILURE = 'LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_FAILURE';

type LoadTotalAudienceForCampaignRequest = {
  type: typeof LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_REQUEST,
};

const loadTotalAudienceForCampaignRequest = (): loadTotalAudienceForCampaignRequest => {
  return {
    type: LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_REQUEST,
  };
};

type LoadTotalAudienceForCampaignSuccess = {
  type: typeof LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_SUCCESS,
  payload: Object,
};

const loadTotalAudienceForCampaignSuccess = (audiences: Object): loadTotalAudienceForCampaignSuccess => {
  return {
    type: LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_SUCCESS,
    payload: audiences,
  };
};

type LoadTotalAudienceForCampaignFailure = {
  type: typeof LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_FAILURE,
  error: string,
};

const loadTotalAudienceForCampaignFailure = (error: string): loadTotalAudienceForCampaignFailure => {
  return {
    type: LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_FAILURE,
    error,
  };
};

export type TotalAudienceForCampaignActions =
  | LoadTotalAudienceForCampaignRequest
  | LoadTotalAudienceForCampaignSuccess
  | LoadTotalAudienceForCampaignFailure;

export const loadTotalAudienceForCampaign = (campaignId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadTotalAudienceForCampaignRequest());

    let filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/campaign/${campaignId}/cumulative-audience${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadTotalAudienceForCampaignSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadTotalAudienceForCampaignFailure(error));
    return error;
  }
};
