// @flow
import * as actions from '../../actions/movieByAudience/audienceByWeekStacked';

type State = {
  isFetching: boolean,
  audienceByWeekStackedForMovieResults: Array<any>,
  messahe: String,
};

const intialState = {
  isFetching: false,
  audienceByWeekStackedForMovieResults: [],
  message: "",
};

export const audienceByWeekStacked = (
  state: State = intialState,
  action: AudienceByWeekStackedForWeekActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByWeekStackedForMovieResults: []
      }
    case actions.LOAD_AUDIENCE_BY_WEEK_STACKED_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_STACKED_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_STACKED_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        audienceByWeekStackedForMovieResults: action.payload.audience_by_week,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
