// @flow
import * as actions from '../../actions/genreByAudience/aggregatedIndexStatByDateAndGenre';

import type { AggregatedIndexStatByDateAndGenreActions } from '../../actions/genreByAudience/aggregatedIndexStatByDateAndGenre';

type State = {
  isFetching: boolean,
  aggregatedIndexStatByDateAndGenreResults: string,
  message: string,
};

const intialState = {
  isFetching: false,
  aggregatedIndexStatByDateAndGenreResults: "",
  message: "",
};

export const aggregatedIndexStatByDateAndGenre = (
  state: State = intialState,
  action: AggregatedIndexStatByDateAndGenreActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        aggregatedIndexStatByDateAndGenreResults: "",
        message: ""
      }
    case actions.LOAD_AGGREGATED_INDEX_STAT_BY_DATE_AND_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AGGREGATED_INDEX_STAT_BY_DATE_AND_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AGGREGATED_INDEX_STAT_BY_DATE_AND_GENRE_SUCCESS:
      return {
        ...state,
        aggregatedIndexStatByDateAndGenreResults: action.payload.aggregated_genre_rating_index,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
