// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_REQUEST = 'LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_REQUEST';
export const LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_SUCCESS = 'LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_SUCCESS';
export const LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_FAILURE = 'LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_FAILURE';

type LoadFtaTvConsumptionByDateAndGenreRequest = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_REQUEST,
};

const loadFtaTvConsumptionByDateAndGenreRequest = (): loadFtaTvConsumptionByDateAndGenreRequest => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_REQUEST,
  };
};

type LoadFtaTvConsumptionByDateAndGenreSuccess = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_SUCCESS,
  payload: Object,
};

const loadFtaTvConsumptionByDateAndGenreSuccess = (fta_tv_consumption: Object): loadFtaTvConsumptionByDateAndGenreSuccess => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_SUCCESS,
    payload: fta_tv_consumption, 
  };
};

type LoadFtaTvConsumptionByDateAndGenreFailure = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_FAILURE,
  error: string,
};

const loadFtaTvConsumptionByDateAndGenreFailure = (error: string): loadFtaTvConsumptionByDateAndGenreFailure => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_FAILURE,
    error,
  };
};

export type FtaTvConsumptionByDateAndGenreActions =
  | LoadFtaTvConsumptionByDateAndGenreRequest
  | LoadFtaTvConsumptionByDateAndGenreSuccess
  | LoadFtaTvConsumptionByDateAndGenreFailure;

export const loadFtaTvConsumptionByDateAndGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadFtaTvConsumptionByDateAndGenreRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    // /api/date/fta-tv-consumption?date_from=05092019&date_to=09122021&filter_ids=101&genres=aa,df&ratings=1,2
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/fta-tv-consumption?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadFtaTvConsumptionByDateAndGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadFtaTvConsumptionByDateAndGenreFailure(error));
    return error;
  }
};
