// @flow
import * as actions from '../../actions/common/genres';

import type { MovieActions } from '../../actions/common/genres';

type State = {
  isFetching: boolean,
  genreResults: Array<any>,
};

const initial = {
  isFetching: false,
  genreResults: [],
};

export const genres = (
  state: State = initial,
  action: MovieActions
) => {
  switch (action.type) {
    case actions.LOAD_GENRES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_GENRES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_GENRES_SUCCESS:
      return {
        ...state,
        genreResults: action.payload.sort(function(a,b){ var x = a.genreDesc < b.genreDesc? -1:1; return x; }), //sort movies alphabetically
        isFetching: false,
      };
    default:
      return state;
  }
};
