// @flow
import * as actions from '../../actions/campaignByAudience/totalAudienceForCampaign';

import type { TotalAudienceForCampaignActions } from '../../actions/campaignByAudience/totalAudienceForCampaign';

type State = {
  isFetching: boolean,
  results: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  results: [],
  message: "",
};

export const totalAudienceForCampaign = (
  state: State = intialState,
  action: TotalAudienceForCampaignActions
) => {
  switch (action.type) {
    case 'RESET_CAMPAIGN_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        results: []
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_CAMPAIGN_SUCCESS:
      return {
        ...state,
        results: action.payload.total_audience,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
