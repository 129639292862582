// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_TOTAL_AUDIENCE_FOR_MOVIE_REQUEST = 'LOAD_TOTAL_AUDIENCE_FOR_MOVIE_REQUEST';
export const LOAD_TOTAL_AUDIENCE_FOR_MOVIE_SUCCESS = 'LOAD_TOTAL_AUDIENCE_FOR_MOVIE_SUCCESS';
export const LOAD_TOTAL_AUDIENCE_FOR_MOVIE_FAILURE = 'LOAD_TOTAL_AUDIENCE_FOR_MOVIE_FAILURE';

type LoadTotalAudienceForMovieRequest = {
  type: typeof LOAD_TOTAL_AUDIENCE_FOR_MOVIE_REQUEST,
};

const loadTotalAudienceForMovieRequest = (): loadTotalAudienceForMovieRequest => {
  return {
    type: LOAD_TOTAL_AUDIENCE_FOR_MOVIE_REQUEST,
  };
};

type LoadTotalAudienceForMovieSuccess = {
  type: typeof LOAD_TOTAL_AUDIENCE_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadTotalAudienceForMovieSuccess = (audiences: Object): loadTotalAudienceForMovieSuccess => {
  return {
    type: LOAD_TOTAL_AUDIENCE_FOR_MOVIE_SUCCESS,
    payload: audiences, //sort ascending by title before returning
  };
};

type LoadTotalAudienceForMovieFailure = {
  type: typeof LOAD_TOTAL_AUDIENCE_FOR_MOVIE_FAILURE,
  error: string,
};

const loadTotalAudienceForMovieFailure = (error: string): loadTotalAudienceForMovieFailure => {
  return {
    type: LOAD_TOTAL_AUDIENCE_FOR_MOVIE_FAILURE,
    error,
  };
};

export type TotalAudienceForMovieActions =
  | LoadTotalAudienceForMovieRequest
  | LoadTotalAudienceForMovieSuccess
  | LoadTotalAudienceForMovieFailure;

export const loadTotalAudienceForMovie = (movieId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadTotalAudienceForMovieRequest());
    var filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/cumulative-audience${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadTotalAudienceForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadTotalAudienceForMovieFailure(error));
    return error;
  }
};
