// @flow
import 'react-app-polyfill/ie11'; // For IE 11 support

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { unregister } from './serviceWorker';
import configureStore from './store/configureStore';
import { App } from './app';

import './assets/scss/index.scss';

import type { Store } from 'redux';

// $FlowFixMe
const store: Store = configureStore();
const root = document.getElementById('root');

if (root) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    root
  );
} else {
  throw new Error('No root div to mount application.');
}

// Disabling service workers due to issues with users seeing old versions of app
unregister();