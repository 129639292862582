// @flow
import * as actions from '../../actions/genreByAudience/ageByGenre';

import type { AgeActions } from '../../actions/genreByAudience/ageByGenre';

type State = {
  isFetching: boolean,
  ageResults: Array<any>,
};

const intialState = {
  isFetching: false,
  ageByGenderResults: [],
};

export const ageByGenre = (
  state: State = intialState,
  action: AgeActions
) => {
  switch (action.type) {
    case 'RESET_GENRE_BY_AUDIENCE_DATA':
        return {
          ...state,
          isFetching: false,
          ageByGenderResults: []
        }
    case actions.LOAD_AGE_FOR_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AGE_FOR_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AGE_FOR_GENRE_SUCCESS:
      return {
        ...state,
        ageByGenderResults: action.payload.age,
        isFetching: false,
      };
    default:
      return state;
  }
};
