// @flow
import * as actions from '../../actions/campaignByAudience/campaigns';
import type { CampaignActions } from '../../actions/campaignByAudience/campaigns';

type State = {
  isFetching: boolean,
  items: Array<any>,
};

const initial = {
  isFetching: false,
  items: [],
};

export const campaigns = (
  state: State = initial,
  action: CampaignActions
) => {
  switch (action.type) {
    case actions.LOAD_CAMPAIGNS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
