// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_GENDER_FOR_MOVIE_REQUEST = 'LOAD_GENDER_FOR_MOVIE_REQUEST';
export const LOAD_GENDER_FOR_MOVIE_SUCCESS = 'LOAD_GENDER_FOR_MOVIE_SUCCESS';
export const LOAD_GENDER_FOR_MOVIE_FAILURE = 'LOAD_GENDER_FOR_MOVIE_FAILURE';

type LoadGenderForMovieRequest = {
  type: typeof LOAD_GENDER_FOR_MOVIE_REQUEST,
};

const loadGenderForMovieRequest = (): loadGenderForMovieRequest => {
  return {
    type: LOAD_GENDER_FOR_MOVIE_REQUEST,
  };
};

type LoadGenderForMovieSuccess = {
  type: typeof LOAD_GENDER_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadGenderForMovieSuccess = (genders: Object): loadGenderForMovieSuccess => {
  return {
    type: LOAD_GENDER_FOR_MOVIE_SUCCESS,
    payload: genders, //sort ascending by title before returning
  };
};

type LoadGenderForMovieFailure = {
  type: typeof LOAD_GENDER_FOR_MOVIE_FAILURE,
  error: string,
};

const loadGenderForMovieFailure = (error: string): loadGenderForMovieFailure => {
  return {
    type: LOAD_GENDER_FOR_MOVIE_FAILURE,
    error,
  };
};

export type AnnouncementsActions =
  | LoadGenderForMovieRequest
  | LoadGenderForMovieSuccess
  | LoadGenderForMovieFailure;

export const loadGenderForMovie = (movieId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadGenderForMovieRequest());
    var filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/gender${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadGenderForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadGenderForMovieFailure(error));
    return error;
  }
};
