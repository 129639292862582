// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_REQUEST = 'LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_REQUEST';
export const LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_SUCCESS = 'LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_SUCCESS';
export const LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_FAILURE = 'LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_FAILURE';

type LoadAudienceByTopTenMovieByDateAndGenreRequest = {
  type: typeof LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_REQUEST,
};

const loadAudienceByTopTenMovieByDateAndGenreRequest = (): loadAudienceByTopTenMovieByDateAndGenreRequest => {
  return {
    type: LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_REQUEST,
  };
};

type LoadAudienceByTopTenMovieByDateAndGenreSuccess = {
  type: typeof LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_SUCCESS,
  payload: Object,
};

const loadAudienceByTopTenMovieByDateAndGenreSuccess = (audience_by_markets: Object): loadAudienceByTopTenMovieByDateAndGenreSuccess => {
  return {
    type: LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_SUCCESS,
    payload: audience_by_markets, //sort ascending by title before returning
  };
};

type LoadAudienceByTopTenMovieByDateAndGenreFailure = {
  type: typeof LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_FAILURE,
  error: string,
};

const loadAudienceByTopTenMovieByDateAndGenreFailure = (error: string): loadAudienceByTopTenMovieByDateAndGenreFailure => {
  return {
    type: LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_FAILURE,
    error,
  };
};

export type AudienceByTopTenMovieByDateAndGenreActions =
  | LoadAudienceByTopTenMovieByDateAndGenreRequest
  | LoadAudienceByTopTenMovieByDateAndGenreSuccess
  | LoadAudienceByTopTenMovieByDateAndGenreFailure;

export const loadAudienceByTopTenMovieByDateAndGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceByTopTenMovieByDateAndGenreRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/audience-by-movie-top-ten?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceByTopTenMovieByDateAndGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceByTopTenMovieByDateAndGenreFailure(error));
    return error;
  }
};
