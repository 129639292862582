// @flow
import * as actions from '../../actions/genreByAudience/ftaTvConsumptionByDateAndGenre';

import type { FtaTvConsumptionByDateAndGenreActions } from '../../actions/genreByAudience/ftaTvConsumptionByDateAndGenre';

type State = {
  isFetching: boolean,
  ftaTvConsumptionByDateAndGenreResults: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  ftaTvConsumptionByDateAndGenreResults: [],
  message: ""
};

export const ftaTvConsumptionByDateAndGenre = (
  state: State = intialState,
  action: FtaTvConsumptionByDateAndGenreActions
) => {
  switch (action.type) {
    case 'RESET_GENRE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        ftaTvConsumptionByDateAndGenreResults: []
      }
    case actions.LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FTA_TV_CONSUMPTION_BY_DATE_AND_GENRE_SUCCESS:
      return {
        ...state,
        ftaTvConsumptionByDateAndGenreResults: action.payload.fta_tv_consumption,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
