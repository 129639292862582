// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_REQUEST = 'LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_REQUEST';
export const LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_SUCCESS = 'LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_SUCCESS';
export const LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_FAILURE = 'LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_FAILURE';

type LoadAudienceRfStatForCampaignRequest = {
  type: typeof LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_REQUEST,
};

const loadAudienceRfStatForCampaignRequest = (): loadAudienceRfStatForCampaignRequest => {
  return {
    type: LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_REQUEST,
  };
};

type LoadAudienceRfStatForCampaignSuccess = {
  type: typeof LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_SUCCESS,
  payload: Object,
};

const loadAudienceRfStatForCampaignSuccess = (audience: Object): loadAudienceRfStatForCampaignSuccess => {
  return {
    type: LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_SUCCESS,
    payload: audience
  };
};

type LoadAudienceRfStatForCampaignFailure = {
  type: typeof LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_FAILURE,
  error: string,
};

const loadAudienceRfStatForCampaignFailure = (error: string): loadAudienceRfStatForCampaignFailure => {
  return {
    type: LOAD_AUDIENCE_RF_STAT_FOR_CAMPAIGN_FAILURE,
    error,
  };
};

export type AudienceRfStatForCampaignActions =
  | LoadAudienceRfStatForCampaignRequest
  | LoadAudienceRfStatForCampaignSuccess
  | LoadAudienceRfStatForCampaignFailure;

// filter is a comma separated string of the filter ids
export const loadAudienceRfStatForCampaign = (campaignId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceRfStatForCampaignRequest());
    let filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/campaign/${campaignId}/audience-rf-index${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceRfStatForCampaignSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceRfStatForCampaignFailure(error));
    return error;
  }
};
