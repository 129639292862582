// @flow
import React, { lazy } from 'react';
import { Sidebar, Menu, Segment, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../components/Header';
import Footer from '../components/Footer';

type Props = {};
type State = {visible: boolean};

const AppRoutes = lazy(() => import('../app-routes'));

class Root extends React.Component<Props, State> {
  constructor(props){
    super(props);
    this.state = { visible: false };
  }

  setMenuVisibility = (e) => {
    // the pusher pane needs to have the dimmer class on it otherwise the menu is not currently displaying
    // without this it always toggles the menu whenever you click the UI
    if((e.currentTarget.classList.contains("dimmed") && e.currentTarget.classList.contains("dimmed")) || !e.currentTarget.classList.contains("pusher"))
    {
      this.setState({ visible: !this.state.visible});
    }
  };
  render() {
    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation='overlay'
          direction='right'
          icon='labeled'
          inverted
          vertical
          visible={this.state && this.state.visible}
          width='wide'
        >
          <Segment className="mobile-cross-wrapper"><Icon name='close' className="white"  onClick={this.setMenuVisibility.bind(this)} /></Segment>
          <Menu.Item as={Link} to="/cinema-audience" onClick={this.setMenuVisibility.bind(this)}>
            Cinema Audience
          </Menu.Item>
          <Menu.Item as={Link} to="/movie-by-audience" onClick={this.setMenuVisibility.bind(this)}>
            Audience By Movie
          </Menu.Item>
          <Menu.Item as={Link} to="/movie-profiling" onClick={this.setMenuVisibility.bind(this)}>
            Movie Profiling
          </Menu.Item>
          <Menu.Item as={Link} to="/genre-by-audience" onClick={this.setMenuVisibility.bind(this)}>
            Audience by Genre/Rating
          </Menu.Item>
          <Menu.Item as={Link} to="/campaign-by-audience" onClick={this.setMenuVisibility.bind(this)}>
            Campaign Audience R&F
          </Menu.Item>
          <Menu.Item as={Link} to="/help" onClick={this.setMenuVisibility.bind(this)}>
            Help
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher className="root__main-content"  dimmed={this.state && this.state.visible} onClick={this.setMenuVisibility.bind(this)}>
          <Route
            exact={true}
            path="/"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <Route
            exact={true}
            path="/movie-profiling"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <Route
            exact={true}
            path="/cinema-audience"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <Route
            exact={true}
            path="/help"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <Route
            exact={true}
            path="/movie-by-audience"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <Route
            exact={true}
            path="/genre-by-audience"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <Route
            exact={true}
            path="/campaign-by-audience"
            render={props => <Header setMenuVisibility={this.setMenuVisibility} {...props} />}
          />
          <AppRoutes />
          <Route path="/" component={Footer} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>

    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect<Props, {}, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Root);
