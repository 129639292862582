// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_REQUEST = 'LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_REQUEST';
export const LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_SUCCESS = 'LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_SUCCESS';
export const LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_FAILURE = 'LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_FAILURE';

type LoadAudienceByWeekForMovieRequest = {
  type: typeof LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_REQUEST,
};

const loadAudienceByWeekForMovieRequest = (): loadAudienceByWeekForMovieRequest => {
  return {
    type: LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_REQUEST,
  };
};

type LoadAudienceByWeekForMovieSuccess = {
  type: typeof LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadAudienceByWeekForMovieSuccess = (audiences: Object): loadAudienceByWeekForMovieSuccess => {
  return {
    type: LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_SUCCESS,
    payload: audiences, //sort ascending by title before returning
  };
};

type LoadAudienceByWeekForMovieFailure = {
  type: typeof LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_FAILURE,
  error: string,
};

const loadAudienceByWeekForMovieFailure = (error: string): loadAudienceByWeekForMovieFailure => {
  return {
    type: LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_FAILURE,
    error,
  };
};

export type AudienceByWeekForMovieActions =
  | LoadAudienceByWeekForMovieRequest
  | LoadAudienceByWeekForMovieSuccess
  | LoadAudienceByWeekForMovieFailure;

export const loadAudienceByWeekForMovie = (movieId: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceByWeekForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/audience-by-week`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceByWeekForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceByWeekForMovieFailure(error));
    return error;
  }
};
