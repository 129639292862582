// @flow
import * as actions from '../../actions/common/ratings';

import type { MovieActions } from '../../actions/common/ratings';

type State = {
  isFetching: boolean,
  ratingResults: Array<any>,
};

const initial = {
  isFetching: false,
  ratingResults: [],
};

export const ratings = (
  state: State = initial,
  action: MovieActions
) => {
  switch (action.type) {
    case actions.LOAD_RATINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_RATINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_RATINGS_SUCCESS:
      return {
        ...state,
        ratingResults: action.payload.sort(function(a,b){ var x = a.ratingDesc < b.ratingDesc? -1:1; return x; }), //sort movies alphabetically
        isFetching: false,
      };
    default:
      return state;
  }
};
