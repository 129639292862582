// @flow
import * as actions from '../../actions/movieProfiling/activitiesData';

type State = {
  isFetching: boolean,
  activitiesDataForMovieResults: Array<any>,
};

const intialState = {
  isFetching: false,
  activitiesDataForMovieResults: [],
};

export const activitiesData = (
  state: State = intialState,
  action: ActivitiesDataForMovieActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        activitiesDataForMovieResults: []
      }
    case actions.LOAD_ACTIVITIES_DATA_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_ACTIVITIES_DATA_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_ACTIVITIES_DATA_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        activitiesDataForMovieResults: action.payload.activities_data, //TODO Change this once API is changed from using dummy data
        isFetching: false,
      };
    default:
      return state;
  }
};
