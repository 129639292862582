// @flow
import * as actions from '../../actions/genreByAudience/audienceByMarketByDateAndGenre';

import type { AudienceByMarketByDateAndGenreActions } from '../../actions/genreByAudience/audienceByMarketByDateAndGenre';

type State = {
  isFetching: boolean,
  audienceByMarketByDateAndGenreResults: Array<any>,
  message:  string
};

const intialState = {
  isFetching: false,
  audienceByMarketByDateAndGenreResults: [],
  message: ""
};

export const audienceByMarketByDateAndGenre = (
  state: State = intialState,
  action: AudienceByMarketByDateAndGenreActions
) => {
  switch (action.type) {
    case 'RESET_GENRE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByMarketByDateAndGenreResults: [],
        message: ""
      }
    case actions.LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_SUCCESS:
      return {
        ...state,
        audienceByMarketByDateAndGenreResults: action.payload.audience_by_market,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
