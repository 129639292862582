// @flow
type State = {
  results: Array<any>,
};

const initialState = {
  results: [],
};

export const selectedRatings = (state: State = initialState, action: any) => {
  switch (action.type) {
    case "ADD_SELECTED_RATING":
      return {
        ...state,
        results: action.payload,
      };

    default:
      return state;
  }
};
