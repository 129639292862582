// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_REQUEST = 'LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_REQUEST';
export const LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_SUCCESS = 'LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_SUCCESS';
export const LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_FAILURE = 'LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_FAILURE';

type LoadTotalAudienceStatForCampaignRequest = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_REQUEST,
};

const loadTotalAudienceStatForCampaignRequest = (): loadTotalAudienceStatForCampaignRequest => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_REQUEST,
  };
};

type LoadTotalAudienceStatForCampaignSuccess = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_SUCCESS,
  payload: Object,
};

const loadTotalAudienceStatForCampaignSuccess = (audience: Object): loadTotalAudienceStatForCampaignSuccess => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_SUCCESS,
    payload: audience,
  };
};

type LoadTotalAudienceStatForCampaignFailure = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_FAILURE,
  error: string,
};

const loadTotalAudienceStatForCampaignFailure = (error: string): loadTotalAudienceStatForCampaignFailure => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_FOR_CAMPAIGN_FAILURE,
    error,
  };
};

export type TotalAudienceStatForCampaignActions =
  | LoadTotalAudienceStatForCampaignRequest
  | LoadTotalAudienceStatForCampaignSuccess
  | LoadTotalAudienceStatForCampaignFailure;

export const loadTotalAudienceStatForCampaign = (campaignId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadTotalAudienceStatForCampaignRequest());

    var filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/campaign/${campaignId}/total-cinema-delivery${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadTotalAudienceStatForCampaignSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadTotalAudienceStatForCampaignFailure(error));
    return error;
  }
};
