// @flow
import * as actions from '../../actions/movieProfiling/age';

import type { AgeActions } from '../../actions/movieProfiling/age';

type State = {
  isFetching: boolean,
  ageResults: Array<any>,
};

const intialState = {
  isFetching: false,
  ageResults: [],
};

export const age = (
  state: State = intialState,
  action: AgeActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
        return {
          ...state,
          isFetching: false,
          ageResults: []
        }
    case actions.LOAD_AGE_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AGE_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AGE_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        ageResults: action.payload.age,
        isFetching: false,
      };
    default:
      return state;
  }
};
