// @flow
import * as actions from '../../actions/movieProfiling/audienceByWeek';

type State = {
  isFetching: boolean,
  audienceByWeekForMovieResults: Array<any>,
};

const intialState = {
  isFetching: false,
  audienceByWeekForMovieResults: [],
};

export const audienceByWeek = (
  state: State = intialState,
  action: AudienceByWeekForWeekActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
        return {
          ...state,
          isFetching: false,
          audienceByWeekForMovieResults: []
        }
    case actions.LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        audienceByWeekForMovieResults: action.payload.audience_by_week,
        isFetching: false,
      };
    default:
      return state;
  }
};
