// @flow

const initialState = {
  showUpdatedMessage: false
}

type State = {
  showUpdatedMessage: boolean
}

export const showUpdatedMessage = (state: State = initialState, action: any) => {
  switch (action.type) {
    case 'RESET_SELECTED_FILTERS':
      return false;
    case 'SET_UPDATED_MESSAGE':
      return true;
    case 'CLEAR_UPDATED_MESSAGE':
      return false;
    default:
      return state;
  }
}
