// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_REQUEST = 'LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_REQUEST';
export const LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_SUCCESS = 'LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_SUCCESS';
export const LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_FAILURE = 'LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_FAILURE';

type LoadAudienceByMarketByDateAndGenreRequest = {
  type: typeof LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_REQUEST,
};

const loadAudienceByMarketByDateAndGenreRequest = (): loadAudienceByMarketByDateAndGenreRequest => {
  return {
    type: LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_REQUEST,
  };
};

type LoadAudienceByMarketByDateAndGenreSuccess = {
  type: typeof LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_SUCCESS,
  payload: Object,
};

const loadAudienceByMarketByDateAndGenreSuccess = (audience_by_markets: Object): loadAudienceByMarketByDateAndGenreSuccess => {
  return {
    type: LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_SUCCESS,
    payload: audience_by_markets, //sort ascending by title before returning
  };
};

type LoadAudienceByMarketByDateAndGenreFailure = {
  type: typeof LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_FAILURE,
  error: string,
};

const loadAudienceByMarketByDateAndGenreFailure = (error: string): loadAudienceByMarketByDateAndGenreFailure => {
  return {
    type: LOAD_AUDIENCE_BY_MARKET_BY_DATE_AND_GENRE_FAILURE,
    error,
  };
};

export type AudienceByMarketByDateAndGenreActions =
  | LoadAudienceByMarketByDateAndGenreRequest
  | LoadAudienceByMarketByDateAndGenreSuccess
  | LoadAudienceByMarketByDateAndGenreFailure;

export const loadAudienceByMarketByDateAndGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceByMarketByDateAndGenreRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    // /api/date/audience-by-market?date_from=05092019&date_to=09122021&filter_ids=101&genres=aa,df&ratings=1,2
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/audience-by-market?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceByMarketByDateAndGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceByMarketByDateAndGenreFailure(error));
    return error;
  }
};
