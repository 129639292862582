// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_DATA_UPDATE_INFO_REQUEST = 'LOAD_DATA_UPDATE_INFO_REQUEST';
export const LOAD_DATA_UPDATE_INFO_SUCCESS = 'LOAD_DATA_UPDATE_INFO_SUCCESS';
export const LOAD_DATA_UPDATE_INFO_FAILURE = 'LOAD_DATA_UPDATE_INFO_FAILURE';

type LoadDataUploadInfoRequest = {
  type: typeof LOAD_DATA_UPDATE_INFO_REQUEST,
};

const loadDataUploadInfoRequest = (): LoadDataUploadInfoRequest => {
  return {
    type: LOAD_DATA_UPDATE_INFO_REQUEST,
  };
};

type LoadDataUploadInfoSuccess = {
  type: typeof LOAD_DATA_UPDATE_INFO_SUCCESS,
  payload: Object,
};

const loadDataUploadInfoSuccess = (info: Object): LoadDataUploadInfoSuccess => {
  return {
    type: LOAD_DATA_UPDATE_INFO_SUCCESS,
    payload: info,
  };
};

type LoadDataUploadInfoFailure = {
  type: typeof LOAD_DATA_UPDATE_INFO_FAILURE,
  error: string,
};

const loadDataUploadInfoFailure = (error: string): LoadDataUploadInfoFailure => {
  return {
    type: LOAD_DATA_UPDATE_INFO_FAILURE,
    error,
  };
};

export type LoadDataUploadInfoActions =
  | LoadDataUploadInfoRequest
  | LoadDataUploadInfoSuccess
  | LoadDataUploadInfoFailure;

export const loadDataUploadInfo = (): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadDataUploadInfoRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/fusion/info`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadDataUploadInfoSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadDataUploadInfoFailure(error));
    return error;
  }
};
