// @flow
import * as actions from '../../actions/common/loadFilters';
import type { LoadFiltersActions } from '../../actions/common/loadFilters';

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: string
};

const initialState = {
  isFetching: false,
  results: [],
  error: ''
};

export const filters = (
  state: State = initialState,
  action: LoadFiltersActions
) => {
  switch (action.type) {
    case actions.LOAD_FILTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FILTERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case actions.LOAD_FILTERS_SUCCESS:
      return {
        ...state,
        results: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
