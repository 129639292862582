// @flow

export const isNZCinetam = (): boolean =>
  process.env.REACT_APP_COUNTRY === 'nz' || window.location.host.includes('nz');

export const getBaseUrlApi = (): string => {
  switch(process.env.REACT_APP_ENV) {
    case 'prod':
      // TODO: Set this is Prod once available
      return 'https://cinetamapi.roymorgan.com';
    case 'uat':
      return 'https://cinetamapi.roymorganuat.com';
    default:
      return 'https://cinetamapi.roymorgandev.com';
  }
};

export const getBaseUrl = (): string => {
  var suffix = isNZCinetam() ? '/nz' : '/au';
  var baseApiUri = getBaseUrlApi();
  return baseApiUri + suffix;
};

export function getMaxValue(data, key) {
  let result = 0;

  if (data.length <= 0) {
    return result;
  }

  data.forEach(dataLine => {
    if (dataLine[key] > result) {
      result = dataLine[key];
    }
  });
  return result;
}

export function ddmmyyy(dateIn) {
   var yyyy = dateIn.getFullYear();
   var mm = dateIn.getMonth()+1; // getMonth() is zero-based
   var dd  = dateIn.getDate();

   var formattedDay = dd < 10 ? String("0" + dd) : String(dd);
   var formattedMonth = mm < 10 ? String("0" + mm) : String(mm);
   return String(formattedDay + formattedMonth + yyyy); // Leading zeros for mm and dd
}
export function ddmmyyyDividers(dateIn, divider) {
   var yyyy = dateIn.getFullYear();
   var mm = dateIn.getMonth()+1; // getMonth() is zero-based
   var dd  = dateIn.getDate();

   var formattedDay = dd < 10 ? String("0" + dd) : String(dd);
   var formattedMonth = mm < 10 ? String("0" + mm) : String(mm);
   return String(formattedDay + divider + formattedMonth + divider + yyyy); // Leading zeros for mm and dd
}

/**
 * Appends the Category Slug field to each filter object
 *
 * @param {array} filters - The current set of filters
 * @param {string} categorySlug - The current parent slug
 */
export function categoryLabelFilters(filters: Array<{}>, categorySlug: string): Array<{}> {
  let results = [];

  filters.forEach(filter => {
    if (filter.children && filter.children.length >= 0) {
      let newSlug;
      if (categorySlug) {
        newSlug = `${categorySlug} > ${filter.categoryLabel}`;
      } else {
        newSlug = filter.categoryLabel;
      }

      results.push({
        categoryId: filter.categoryId,
        categoryLabel: filter.categoryLabel,
        categorySlug: categorySlug,
        children: categoryLabelFilters(filter.children, newSlug)
      })
    } else {
      results.push({
        categoryId: filter.categoryId,
        categoryLabel: filter.categoryLabel,
        categorySlug: categorySlug
      })
    }
  })

  return results;
}

export function flattenFilters(filters) {
  let results = [];

  filters.forEach(filter => {
    let isParent = false;

    if (filter.children && filter.children.length > 0) {
      results.push(...flattenFilters(filter.children));
      isParent = true;
    }

    results.push(
      {
        categoryId: filter.categoryId,
        categoryLabel: filter.categoryLabel,
        categorySlug: filter.categorySlug,
        isParent: isParent
      }
    );
  })

  return results;
}
