
import jsPDF from 'jspdf';
import cinetamWhiteLogo from '../assets/images/cinetam-logo-white.png';
import valMorganWhiteLogo from '../assets/images/val-morgan-logo-white.png';

export function addHeaderAndFooter(doc: jsPDF, title: String) {

    const fullWidth = 595;
    const pageBottom = 842;
    doc.setFillColor(14,49,81);
    doc.setTextColor(255,255,255);
    //Header background, then logos
    doc.rect(0, 0, fullWidth, 40, 'F');
    doc.addImage(valMorganWhiteLogo, 'PNG', 25, 15.5, 100, 11.33);
    doc.addImage(cinetamWhiteLogo, 'PNG', 135, 13, 100, 14.66);
    doc.setFontSize(15);
    doc.text(fullWidth - (doc.getTextWidth(title) + 25), 25, title);
    doc.setFillColor(14,49,81);
    doc.setTextColor(255,255,255);
    doc.rect(0, pageBottom - 40, fullWidth, pageBottom, 'F');
    doc.setFontSize(6);
    doc.text(25, pageBottom - 15, "Copyright - 2024 Val Morgan Cinema Network");
    doc.text(fullWidth - 175, pageBottom - 15, "Powered by CineTAM fused with Roy Morgan Single Source");
}

export function addFilters(doc: jsPDF, selectedFiltersasd: Array) {


}
