// @flow
import * as actions from '../../actions/movieByAudience/uniqueAudienceStat';

import type { UniqueAudienceStatActions } from '../../actions/movieByAudience/uniqueAudienceStat';

type State = {
  isFetching: boolean,
  uniqueAudienceStatForMovieResults: string,
  message: string,
};

const intialState = {
  isFetching: false,
  uniqueAudienceStatForMovieResults: "",
  message: "",
};

export const uniqueAudienceStat = (
  state: State = intialState,
  action: UniqueAudienceStatActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        uniqueAudienceStatForMovieResults: ""
      }
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        uniqueAudienceStatForMovieResults: action.payload.unique_cinema_delivery,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
