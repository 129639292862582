// @flow
import * as actions from '../../actions/common/setSearchString';

type State = {
  searchString: string
};

const initialState = {
  searchString: ''
};

export const filterSearch = (
  state: State = initialState,
  action: any
) => {
  switch (action.type) {
    case 'RESET_SELECTED_FILTERS':
      return {
        ...state,
        searchString: ''
      }
    case actions.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload
      };
    default:
      return state;
  }
};
