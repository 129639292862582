// @flow
import * as actions from '../../actions/cinemaAudience/audienceByMarketByDate';

import type { AudienceByMarketByDateActions } from '../../actions/cinemaAudience/audienceByMarketByDate';

type State = {
  isFetching: boolean,
  audienceByMarketByDateResults: Array<any>,
  message:  string
};

const intialState = {
  isFetching: false,
  audienceByMarketByDateResults: [],
  message: ""
};

export const audienceByMarketByDate = (
  state: State = intialState,
  action: AudienceByMarketByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByMarketByDateResults: [],
        message: ""
      }
    case actions.LOAD_AUDIENCE_BY_MARKET_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_MARKET_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_MARKET_BY_DATE_SUCCESS:
      return {
        ...state,
        audienceByMarketByDateResults: action.payload.audience_by_market,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
