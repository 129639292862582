// @flow
import * as actions from '../../actions/movieByAudience/totalAudienceStat';

import type { TotalAudienceStatActions } from '../../actions/movieByAudience/totalAudienceStat';

type State = {
  isFetching: boolean,
  totalAudienceStatForMovieResults: string,
  message: String,
};

const intialState = {
  isFetching: false,
  totalAudienceStatForMovieResults: "",
  message: ""
};

export const totalAudienceStat = (
  state: State = intialState,
  action: TotalAudienceStatActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        totalAudienceStatForMovieResults: ''
      }
    case actions.LOAD_TOTAL_AUDIENCE_STAT_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_TOTAL_AUDIENCE_STAT_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_TOTAL_AUDIENCE_STAT_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        totalAudienceStatForMovieResults: action.payload.total_cinema_delivery,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
