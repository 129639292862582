// @flow
import * as actions from '../../actions/genreByAudience/audienceByTopTenMovieByDateAndGenre';

import type { AudienceByTopTenMovieByDateAndGenreActions } from '../../actions/genreByAudience/audienceByTopTenMovieByDateAndGenre';

type State = {
  isFetching: boolean,
  audienceByTopTenMovieByDateAndGenreResults: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  audienceByTopTenMovieByDateAndGenreResults: [],
  message: ""
};

export const audienceByTopTenMovieByDateAndGenre = (
  state: State = intialState,
  action: AudienceByTopTenMovieByDateAndGenreActions
) => {
  switch (action.type) {
    case 'RESET_GENRE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByTopTenMovieByDateAndGenreResults: [],
        message: ""
      }
    case actions.LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_TOP_TEN_MOVIE_BY_DATE_AND_GENRE_SUCCESS:
      return {
        ...state,
        audienceByTopTenMovieByDateAndGenreResults: action.payload.audience_by_movie_top_ten,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
