import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoadingIndicator from './components/LoadingIndicator';
import Root from './containers/Root';


function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
        <Root />
      </Suspense>
    </Router>
  );
}

export { App };
