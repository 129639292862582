// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AGE_FOR_MOVIE_REQUEST = 'LOAD_AGE_FOR_MOVIE_REQUEST';
export const LOAD_AGE_FOR_MOVIE_SUCCESS = 'LOAD_AGE_FOR_MOVIE_SUCCESS';
export const LOAD_AGE_FOR_MOVIE_FAILURE = 'LOAD_AGE_FOR_MOVIE_FAILURE';

type LoadAgeForMovieRequest = {
  type: typeof LOAD_AGE_FOR_MOVIE_REQUEST,
};

const loadAgeForMovieRequest = (): loadAgeForMovieRequest => {
  return {
    type: LOAD_AGE_FOR_MOVIE_REQUEST,
  };
};

type LoadAgeForMovieSuccess = {
  type: typeof LOAD_AGE_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadAgeForMovieSuccess = (ages: Object): loadAgeForMovieSuccess => {
  return {
    type: LOAD_AGE_FOR_MOVIE_SUCCESS,
    payload: ages, //sort ascending by title before returning
  };
};

type LoadAgeForMovieFailure = {
  type: typeof LOAD_AGE_FOR_MOVIE_FAILURE,
  error: string,
};

const loadAgeForMovieFailure = (error: string): loadAgeForMovieFailure => {
  return {
    type: LOAD_AGE_FOR_MOVIE_FAILURE,
    error,
  };
};

export type AgeActions =
  | LoadAgeForMovieRequest
  | LoadAgeForMovieSuccess
  | LoadAgeForMovieFailure;

export const loadAgeForMovie = (movieId: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAgeForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/age`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAgeForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAgeForMovieFailure(error));
    return error;
  }
};
