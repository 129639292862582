// @flow
import * as actions from '../../actions/common/moviePoster';

import type { MoviePosterActions } from '../../actions/common/moviePoster';

type State = {
  isFetching: boolean,
  moviePoster: String,
};

const initial = {
  isFetching: false,
  moviePoster: '',
};

export const moviePoster = (
  state: State = initial,
  action: MoviePosterActions
) => {
  switch (action.type) {
    case actions.LOAD_MOVIE_POSTER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_MOVIE_POSTER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_MOVIE_POSTER_SUCCESS:
      return {
        ...state,
        moviePoster: action.payload.movie_poster_image,
        isFetching: false,
      };
    default:
      return state;
  }
};
