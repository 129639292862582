// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AGE_FOR_GENRE_REQUEST = 'LOAD_AGE_FOR_GENRE_REQUEST';
export const LOAD_AGE_FOR_GENRE_SUCCESS = 'LOAD_AGE_FOR_GENRE_SUCCESS';
export const LOAD_AGE_FOR_GENRE_FAILURE = 'LOAD_AGE_FOR_GENRE_FAILURE';

type LoadAgeForGenreRequest = {
  type: typeof LOAD_AGE_FOR_GENRE_REQUEST,
};

const loadAgeForGenreRequest = (): loadAgeForGenreRequest => {
  return {
    type: LOAD_AGE_FOR_GENRE_REQUEST,
  };
};

type LoadAgeForGenreSuccess = {
  type: typeof LOAD_AGE_FOR_GENRE_SUCCESS,
  payload: Object,
};

const loadAgeForGenreSuccess = (ages: Object): loadAgeForGenreSuccess => {
  return {
    type: LOAD_AGE_FOR_GENRE_SUCCESS,
    payload: ages, //sort ascending by title before returning
  };
};

type LoadAgeForGenreFailure = {
  type: typeof LOAD_AGE_FOR_GENRE_FAILURE,
  error: string,
};

const loadAgeForGenreFailure = (error: string): loadAgeForGenreFailure => {
  return {
    type: LOAD_AGE_FOR_GENRE_FAILURE,
    error,
  };
};

export type AgeActions =
  | LoadAgeForGenreRequest
  | LoadAgeForGenreSuccess
  | LoadAgeForGenreFailure;

export const loadAgeForGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<GenresActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAgeForGenreRequest());

    var filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    // /api/date/age?date_from=05092019&date_to=09122021&filter_ids=101&genres=aa,df&ratings=1,2
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/age?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAgeForGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAgeForGenreFailure(error));
    return error;
  }
};
