// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_FTA_TV_CONSUMPTION_BY_DATE_REQUEST = 'LOAD_FTA_TV_CONSUMPTION_BY_DATE_REQUEST';
export const LOAD_FTA_TV_CONSUMPTION_BY_DATE_SUCCESS = 'LOAD_FTA_TV_CONSUMPTION_BY_DATE_SUCCESS';
export const LOAD_FTA_TV_CONSUMPTION_BY_DATE_FAILURE = 'LOAD_FTA_TV_CONSUMPTION_BY_DATE_FAILURE';

type LoadFtaTvConsumptionByDateRequest = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_BY_DATE_REQUEST,
};

const loadFtaTvConsumptionByDateRequest = (): loadFtaTvConsumptionByDateRequest => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_BY_DATE_REQUEST,
  };
};

type LoadFtaTvConsumptionByDateSuccess = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_BY_DATE_SUCCESS,
  payload: Object,
};

const loadFtaTvConsumptionByDateSuccess = (fta_tv_consumption: Object): loadFtaTvConsumptionByDateSuccess => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_BY_DATE_SUCCESS,
    payload: fta_tv_consumption, 
  };
};

type LoadFtaTvConsumptionByDateFailure = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_BY_DATE_FAILURE,
  error: string,
};

const loadFtaTvConsumptionByDateFailure = (error: string): loadFtaTvConsumptionByDateFailure => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_BY_DATE_FAILURE,
    error,
  };
};

export type FtaTvConsumptionByDateActions =
  | LoadFtaTvConsumptionByDateRequest
  | LoadFtaTvConsumptionByDateSuccess
  | LoadFtaTvConsumptionByDateFailure;

export const loadFtaTvConsumptionByDate = (date_from: string,date_to: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadFtaTvConsumptionByDateRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/fta-tv-consumption?date_from=${date_from}&date_to=${date_to}${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadFtaTvConsumptionByDateSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadFtaTvConsumptionByDateFailure(error));
    return error;
  }
};
