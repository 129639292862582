// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST = 'LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST';
export const LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS = 'LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS';
export const LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE = 'LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE';

type LoadUniqueAudienceStatByDateAndGenreRequest = {
  type: typeof LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST,
};

const loadUniqueAudienceStatByDateAndGenreRequest = (): loadUniqueAudienceStatByDateAndGenreRequest => {
  return {
    type: LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST,
  };
};

type LoadUniqueAudienceStatByDateAndGenreSuccess = {
  type: typeof LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS,
  payload: Object,
};

const loadUniqueAudienceStatByDateAndGenreSuccess = (audience: Object): loadUniqueAudienceStatByDateAndGenreSuccess => {
  return {
    type: LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS,
    payload: audience, //sort ascending by title before returning
  };
};

type LoadUniqueAudienceStatByDateAndGenreFailure = {
  type: typeof LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE,
  error: string,
};

const loadUniqueAudienceStatByDateAndGenreFailure = (error: string): loadUniqueAudienceStatByDateAndGenreFailure => {
  return {
    type: LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE,
    error,
  };
};

export type UniqueAudienceStatByDateAndGenreActions =
  | LoadUniqueAudienceStatByDateAndGenreRequest
  | LoadUniqueAudienceStatByDateAndGenreSuccess
  | LoadUniqueAudienceStatByDateAndGenreFailure;

export const loadUniqueAudienceStatByDateAndGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadUniqueAudienceStatByDateAndGenreRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/unique-audience-delivery?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadUniqueAudienceStatByDateAndGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadUniqueAudienceStatByDateAndGenreFailure(error));
    return error;
  }
};
