// @flow
import * as actions from '../../actions/genreByAudience/genderForGenre';

type State = {
  isFetching: boolean,
  genderForGenreResults: Array<any>,
  message: String
};

const initialState = {
  isFetching: false,
  genderForGenreResults: [],
  message: ''
};

export const genderForGenre = (
  state: State = initialState,
  action: GenderForGenreActions
) => {
  switch (action.type) {
    case 'RESET_GENRE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        genderForGenreResults: []
      }
    case actions.LOAD_GENDER_FOR_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_GENDER_FOR_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_GENDER_FOR_GENRE_SUCCESS:
      return {
        ...state,
        genderForGenreResults: action.payload.gender,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
