// @flow
type State = {
  results: Array<any>,
};

const initialState = {
  results: [],
};

export const selectedGenres = (state: State = initialState, action: any) => {
  switch (action.type) {
    case "ADD_SELECTED_GENRE":

      return {
        ...state,
        results: action.payload,
      };
    
    default:
      return state;
  }
};
