// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl, categoryLabelFilters } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_FILTERS_REQUEST = 'LOAD_FILTERS_REQUEST';
export const LOAD_FILTERS_SUCCESS = 'LOAD_FILTERS_SUCCESS';
export const LOAD_FILTERS_FAILURE = 'LOAD_FILTERS_FAILURE';

type LoadFiltersRequest = {
  type: typeof LOAD_FILTERS_REQUEST,
};

const loadFiltersRequest = (): LoadFiltersRequest => {
  return {
    type: LOAD_FILTERS_REQUEST,
  };
};

type LoadFiltersSuccess = {
  type: typeof LOAD_FILTERS_SUCCESS,
  payload: Object,
};

const loadFiltersSuccess = (filters: Object): LoadFiltersSuccess => {
  return {
    type: LOAD_FILTERS_SUCCESS,
    payload: filters,
  };
};

type LoadFiltersFailure = {
  type: typeof LOAD_FILTERS_FAILURE,
  error: string,
};

const loadFiltersFailure = (error: string): LoadFiltersFailure => {
  return {
    type: LOAD_FILTERS_FAILURE,
    error,
  };
};

export type LoadFiltersActions =
  | LoadFiltersRequest
  | LoadFiltersSuccess
  | LoadFiltersFailure;

export const loadFilters = (page: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadFiltersRequest());
    let pageParam = "";
    if(page && page.length > 0){
      pageParam = "?page=" + page;
    }
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/filters${pageParam}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadFiltersSuccess(categoryLabelFilters(response, '')));

    return response;
  } catch (error) {
    dispatch(loadFiltersFailure(error));
    return error;
  }
};
