// @flow
type State = {
  results: Array<any>,
};

const initialState = {
  results: [],
};

export const selectedFilters = (
  state: State = initialState,
  action: any
) => {
  switch (action.type) {
    case 'RESET_SELECTED_FILTERS':
      return {
        ...state,
        results: [],
      }
    case 'ADD_SELECTED_FILTER':
      return {
        ...state,
        results: [
          ...state.results,
          action.payload
        ],
      };
    /* eslint-disable no-case-declarations */
    case 'REMOVE_SELECTED_FILTER':
      let copy = state.results;
      // $FlowFixMe
      copy = copy.filter(value => value.categoryId !== action.payload.categoryId);
      return {
        ...state,
        results: [...copy],
      };
    default:
      return state;
  }
};
