// @flow
import * as actions from '../../actions/genreByAudience/totalAudienceStatByDateAndGenre';

import type { TotalAudienceStatByDateAndGenreActions } from '../../actions/genreByAudience/totalAudienceStatByDateAndGenre';

type State = {
  isFetching: boolean,
  totalAudienceStatByDateAndGenreResults: string,
  message: string,
};

const intialState = {
  isFetching: false,
  totalAudienceStatByDateAndGenreResults: "",
  message: "",
};

export const totalAudienceStatByDateAndGenre = (
  state: State = intialState,
  action: TotalAudienceStatByDateAndGenreActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        totalAudienceStatByDateAndGenreResults: "",
        message: ''
      }
    case actions.LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS:
      return {
        ...state,
        totalAudienceStatByDateAndGenreResults: action.payload.total_cinema_delivery,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
