// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl,sortByKey } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_MOVIES_REQUEST = 'LOAD_MOVIES_REQUEST';
export const LOAD_MOVIES_SUCCESS = 'LOAD_MOVIES_SUCCESS';
export const LOAD_MOVIES_FAILURE = 'LOAD_MOVIES_FAILURE';

type LoadMoviesRequest = {
  type: typeof LOAD_MOVIES_REQUEST,
};

const loadMoviesRequest = (): loadMoviesRequest => {
  return {
    type: LOAD_MOVIES_REQUEST,
  };
};

type LoadMoviesSuccess = {
  type: typeof LOAD_MOVIES_SUCCESS,
  payload: Object,
};

const loadMoviesSuccess = (movies: Object): loadMoviesSuccess => {
  return {
    type: LOAD_MOVIES_SUCCESS,
    payload: sortByKey(movies, 'title'), //sort ascending by title before returning
  };
};

type LoadMoviesFailure = {
  type: typeof LOAD_MOVIES_FAILURE,
  error: string,
};

const loadMoviesFailure = (error: string): loadMoviesFailure => {
  return {
    type: LOAD_MOVIES_FAILURE,
    error,
  };
};

export type AnnouncementsActions =
  | LoadMoviesRequest
  | LoadMoviesSuccess
  | LoadMoviesFailure;

export const loadMovies = (): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadMoviesRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movies`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadMoviesSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadMoviesFailure(error));
    return error;
  }
};
