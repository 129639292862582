// @flow
import * as actions from '../../actions/common/dataUpdateInfo';
import type { LoadDataUploadInfoActions } from '../../actions/common/dataUpdateInfo';

type State = {
  isFetching: boolean,
  info: any,
  error: string
};

const initialState = {
  isFetching: false,
  info: null,
  error: ''
};

export const dataUpdateInfo = (
  state: State = initialState,
  action: LoadDataUploadInfoActions
) => {
  switch (action.type) {
    case actions.LOAD_DATA_UPDATE_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_DATA_UPDATE_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case actions.LOAD_DATA_UPDATE_INFO_SUCCESS:
      return {
        ...state,
        info: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
