// @flow
import * as actions from '../../actions/common/movies';

import type { MovieActions } from '../../actions/common/movies';

type State = {
  isFetching: boolean,
  movieResuls: Array<any>,
};

const initial = {
  isFetching: false,
  movieResuls: [],
};

export const movies = (
  state: State = initial,
  action: MovieActions
) => {
  switch (action.type) {
    case actions.LOAD_MOVIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_MOVIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_MOVIES_SUCCESS:
      return {
        ...state,
        movieResuls: action.payload[0].movies.sort(function(a,b){ var x = a.title < b.title? -1:1; return x; }), //sort movies alphabetically
        isFetching: false,
      };
    default:
      return state;
  }
};
