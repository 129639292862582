// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST = 'LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST';
export const LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS = 'LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS';
export const LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE = 'LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE';

type LoadTotalAudienceStatByDateAndGenreRequest = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST,
};

const loadTotalAudienceStatByDateAndGenreRequest = (): loadTotalAudienceStatByDateAndGenreRequest => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST,
  };
};

type LoadTotalAudienceStatByDateAndGenreSuccess = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS,
  payload: Object,
};

const loadTotalAudienceStatByDateAndGenreSuccess = (audience: Object): loadTotalAudienceStatByDateAndGenreSuccess => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS,
    payload: audience, //sort ascending by title before returning
  };
};

type LoadTotalAudienceStatByDateAndGenreFailure = {
  type: typeof LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE,
  error: string,
};

const loadTotalAudienceStatByDateAndGenreFailure = (error: string): loadTotalAudienceStatByDateAndGenreFailure => {
  return {
    type: LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE,
    error,
  };
};

export type TotalAudienceStatByDateAndGenreActions =
  | LoadTotalAudienceStatByDateAndGenreRequest
  | LoadTotalAudienceStatByDateAndGenreSuccess
  | LoadTotalAudienceStatByDateAndGenreFailure;

export const loadTotalAudienceStatByDateAndGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadTotalAudienceStatByDateAndGenreRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/total-cinema-delivery?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadTotalAudienceStatByDateAndGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadTotalAudienceStatByDateAndGenreFailure(error));
    return error;
  }
};
