// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_REQUEST = 'LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_REQUEST';
export const LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_SUCCESS = 'LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_SUCCESS';
export const LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_FAILURE = 'LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_FAILURE';

type LoadAudienceByWeekForCampaignRequest = {
  type: typeof LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_REQUEST,
};

const loadAudienceByWeekForCampaignRequest = (): loadAudienceByWeekForCampaignRequest => {
  return {
    type: LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_REQUEST,
  };
};

type LoadAudienceByWeekForCampaignSuccess = {
  type: typeof LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_SUCCESS,
  payload: Object,
};

const loadAudienceByWeekForCampaignSuccess = (audiences: Object): loadAudienceByWeekForCampaignSuccess => {
  return {
    type: LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_SUCCESS,
    payload: audiences,
  };
};

type LoadAudienceByWeekForCampaignFailure = {
  type: typeof LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_FAILURE,
  error: string,
};

const loadAudienceByWeekForCampaignFailure = (error: string): loadAudienceByWeekForCampaignFailure => {
  return {
    type: LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_FAILURE,
    error,
  };
};

export type AudienceByWeekForCampaignActions =
  | LoadAudienceByWeekForCampaignRequest
  | LoadAudienceByWeekForCampaignSuccess
  | LoadAudienceByWeekForCampaignFailure;

export const loadAudienceByWeekForCampaign = (campaignId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceByWeekForCampaignRequest());

    let filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/campaign/${campaignId}/audience-by-week${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceByWeekForCampaignSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceByWeekForCampaignFailure(error));
    return error;
  }
};
