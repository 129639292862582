// @flow
import * as actions from '../../actions/campaignByAudience/audienceByWeekForCampaign';

import type { AudienceByWeekForCampaignActions} from '../../actions/campaignByAudience/audienceByWeekForCampaign';

type State = {
  isFetching: boolean,
  results: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  results: [],
  message: "",
};

export const audienceByWeekForCampaign = (
  state: State = intialState,
  action: AudienceByWeekForCampaignActions
) => {
  switch (action.type) {
    case 'RESET_CAMPAIGN_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        results: []
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_WEEK_FOR_CAMPAIGN_SUCCESS:
      return {
        ...state,
        results: action.payload.audience_by_week,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
