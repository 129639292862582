// @flow
import * as actions from '../../actions/common/totalAudience';

type State = {
  isFetching: boolean,
  totalAudienceForMovieResults: Array<any>,
  message: String,
};

const initialState = {
  isFetching: false,
  totalAudienceForMovieResults: [],
  message: ''
};

export const totalAudience = (
  state: State = initialState,
  action: TotalAudienceForMovieActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
    case 'RESET_CINEMA_AUDIENCE_DATA':
    case 'RESET_MOVIE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        totalAudienceForMovieResults: []
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        totalAudienceForMovieResults: action.payload.total_audience,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
