// @flow strict
export * from './common';
export * from './converter';
export * from './vmPdf';

export const noCamelize = (key: string, convert: (k: string) => string) => {
  if (key.toLowerCase() === 'id') {
    return 'id';
  }
  return /(^[A-Z0-9_]+$|^id+$)/.test(key) ? key : convert(key);
};

export const slugify = (text: string) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

export const sortByKey = (array, key) =>
{
 return array.sort(function(a, b)
 {
  var x = a[key]; var y = b[key];
  return ((x < y) ? -1 : ((x > y) ? 1 : 0));
 });
}