// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_MOVIE_POSTER_REQUEST = 'LOAD_MOVIE_POSTER_REQUEST';
export const LOAD_MOVIE_POSTER_SUCCESS = 'LOAD_MOVIE_POSTER_SUCCESS';
export const LOAD_MOVIE_POSTER_FAILURE = 'LOAD_MOVIE_POSTER_FAILURE';

type LoadMoviePosterRequest = {
  type: typeof LOAD_MOVIE_POSTER_REQUEST,
};

const loadMoviePosterRequest = (): LoadMoviePosterRequest => {
  return {
    type: LOAD_MOVIE_POSTER_REQUEST,
  };
};

type LoadMoviePosterSuccess = {
  type: typeof LOAD_MOVIE_POSTER_SUCCESS,
  payload: Object,
};

const loadMoviePosterSuccess = (moviePoster: String): LoadMoviePosterSuccess => {
  return {
    type: LOAD_MOVIE_POSTER_SUCCESS,
    payload: moviePoster, //sort ascending by title before returning
  };
};

type LoadMoviePosterFailure = {
  type: typeof LOAD_MOVIE_POSTER_FAILURE,
  error: string,
};

const loadMoviePosterFailure = (error: string): LoadMoviePosterFailure => {
  return {
    type: LOAD_MOVIE_POSTER_FAILURE,
    error,
  };
};

export type AnnouncementsActions =
  | LoadMoviePosterRequest
  | LoadMoviePosterSuccess
  | LoadMoviePosterFailure;

export const loadMoviePoster = (movieId: string): ThunkwithAction<MoviePosterActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadMoviePosterRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/poster`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadMoviePosterSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadMoviePosterFailure(error));
    return error;
  }
};



