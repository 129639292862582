// @flow
import * as actions from '../../actions/cinemaAudience/totalAudienceStatByDate';

import type { TotalAudienceStatByDateActions } from '../../actions/cinemaAudience/totalAudienceStatByDate';

type State = {
  isFetching: boolean,
  totalAudienceStatByDateResults: string,
  message: string,
};

const intialState = {
  isFetching: false,
  totalAudienceStatByDateResults: "",
  message: "",
};

export const totalAudienceStatByDate = (
  state: State = intialState,
  action: TotalAudienceStatByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        totalAudienceStatByDateResults: "",
        message: ''
      }
    case actions.LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_TOTAL_AUDIENCE_STAT_BY_DATE_SUCCESS:
      return {
        ...state,
        totalAudienceStatByDateResults: action.payload.total_cinema_delivery,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
