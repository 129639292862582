// @flow
import * as actions from '../../actions/movieProfiling/consumerProfiling';

type State = {
  isFetching: boolean,
  consumerProfilingForMovieResults: Array<any>,
};

const intialState = {
  isFetching: false,
  consumerProfilingForMovieResults: [],
};

export const consumerProfiling = (
  state: State = intialState,
  action: ConsumerProfilingForMovieActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
        return {
          ...state,
          isFetching: false,
          consumerProfilingForMovieResults: []
        }
    case actions.LOAD_CONSUMER_PROFILING_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_CONSUMER_PROFILING_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        consumerProfilingForMovieResults: action.payload.consumer_profiling,
        isFetching: false,
      };
    default:
      return state;
  }
};
