// @flow
// @flow
import * as React from 'react';
import { Menu, Dropdown,Image,Segment, Grid, Icon,Responsive } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cinetamWhiteLogo from '../assets/images/cinetam-logo-white.png';
import valMorganWhiteLogo from '../assets/images/val-morgan-logo-white.png';

type Props = {
  setMenuVisibility: PropTypes.object
};
type State = {};

class Header extends React.Component<Props, State> {
  render() {
    return(
      <Segment className="main-nav">
          <Grid className="dashboard-grid">
              <Grid.Row>
                <Grid.Column mobile={8} tablet={10} computer={8} className="logos">

                  <Link to="/" ><Image src={valMorganWhiteLogo} /><Image src={cinetamWhiteLogo} /></Link>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={6} computer={8}>
                <Menu className="main-nav__menu">
                  <Responsive as={Dropdown} minWidth={768} text='Get Started' pointing className='getting-started' >
                    <Dropdown.Menu className="getting-started__dropdown" >
                      <Dropdown.Item><Link to="/movie-profiling">Movie Profiling</Link></Dropdown.Item>
                      <Dropdown.Item><Link to="/cinema-audience">Cinema Audience</Link></Dropdown.Item>
                      <Dropdown.Item><Link to="/movie-by-audience">Audience By Movie</Link></Dropdown.Item>
                      <Dropdown.Item><Link to="/genre-by-audience">Audience by Genre/Rating</Link></Dropdown.Item>
                      <Dropdown.Item><Link to="/campaign-by-audience">Campaign Audience R&F</Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Responsive>

                  <Responsive as={Menu.Item} minWidth={768}>
                    <Link to="/help">Help</Link>
                  </Responsive>
                  <Responsive as={Menu.Item} maxWidth={767}>
                    <Icon name='bars' className="white" onClick={this.props.setMenuVisibility.bind(this)} />
                  </Responsive>
                </Menu>
              </Grid.Column>
            </Grid.Row>
          </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect<Props, {}, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Header);
