// @flow
import * as actions from '../../actions/movieProfiling/productData';

import type { productDataForMovieActions } from '../../actions/movieProfiling/productData';

type State = {
  isFetching: boolean,
  productDataForMovieResults: Array<any>,
};

const intialState = {
  isFetching: false,
  productDataForMovieResults: [],
};

export const productData = (
  state: State = intialState,
  action: productDataForMovieActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        productDataForMovieResults: []
      }
    case actions.LOAD_PRODUCT_DATA_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_PRODUCT_DATA_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_PRODUCT_DATA_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        productDataForMovieResults: action.payload.product_data,
        isFetching: false,
      };
    default:
      return state;
  }
};
