// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_GENDER_FOR_GENRE_REQUEST = 'LOAD_GENDER_FOR_GENRE_REQUEST';
export const LOAD_GENDER_FOR_GENRE_SUCCESS = 'LOAD_GENDER_FOR_GENRE_SUCCESS';
export const LOAD_GENDER_FOR_GENRE_FAILURE = 'LOAD_GENDER_FOR_GENRE_FAILURE';

type LoadGenderForGenreRequest = {
  type: typeof LOAD_GENDER_FOR_GENRE_REQUEST,
};

const loadGenderForGenreRequest = (): loadGenderForGenreRequest => {
  return {
    type: LOAD_GENDER_FOR_GENRE_REQUEST,
  };
};

type LoadGenderForGenreSuccess = {
  type: typeof LOAD_GENDER_FOR_GENRE_SUCCESS,
  payload: Object,
};

const loadGenderForGenreSuccess = (genders: Object): loadGenderForGenreSuccess => {
  return {
    type: LOAD_GENDER_FOR_GENRE_SUCCESS,
    payload: genders, //sort ascending by title before returning
  };
};

type LoadGenderForGenreFailure = {
  type: typeof LOAD_GENDER_FOR_GENRE_FAILURE,
  error: string,
};

const loadGenderForGenreFailure = (error: string): loadGenderForGenreFailure => {
  return {
    type: LOAD_GENDER_FOR_GENRE_FAILURE,
    error,
  };
};

export type GenderForGenreActions =
  | LoadGenderForGenreRequest
  | LoadGenderForGenreSuccess
  | LoadGenderForGenreFailure;

export const loadGenderForGenre = (date_from: string,date_to: string,genres: string,ratings: string, filter: string): ThunkwithAction<GenresActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadGenderForGenreRequest());
    var filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    ///api/date/gender?date_from=05092019&date_to=09122021&filter_ids=101&genres=aa,df&ratings=1,2
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/gender?date_from=${date_from}&date_to=${date_to}${filterIds}&genres=${genres}&ratings=${ratings}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadGenderForGenreSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadGenderForGenreFailure(error));
    return error;
  }
};
