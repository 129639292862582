// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_REQUEST = 'LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_REQUEST';
export const LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_SUCCESS = 'LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_SUCCESS';
export const LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_FAILURE = 'LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_FAILURE';

type LoadAudienceByMarketForMovieRequest = {
  type: typeof LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_REQUEST,
};

const loadAudienceByMarketForMovieRequest = (): loadAudienceByMarketForMovieRequest => {
  return {
    type: LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_REQUEST,
  };
};

type LoadAudienceByMarketForMovieSuccess = {
  type: typeof LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadAudienceByMarketForMovieSuccess = (audience_by_markets: Object): loadAudienceByMarketForMovieSuccess => {
  return {
    type: LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_SUCCESS,
    payload: audience_by_markets, //sort ascending by title before returning
  };
};

type LoadAudienceByMarketForMovieFailure = {
  type: typeof LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_FAILURE,
  error: string,
};

const loadAudienceByMarketForMovieFailure = (error: string): loadAudienceByMarketForMovieFailure => {
  return {
    type: LOAD_AUDIENCE_BY_MARKET_FOR_MOVIE_FAILURE,
    error,
  };
};

export type AudienceByMarketForMovieActions =
  | LoadAudienceByMarketForMovieRequest
  | LoadAudienceByMarketForMovieSuccess
  | LoadAudienceByMarketForMovieFailure;

export const loadAudienceByMarketForMovie = (movieId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceByMarketForMovieRequest());
    
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/audience-by-market${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceByMarketForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceByMarketForMovieFailure(error));
    return error;
  }
};
