// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_CONSUMER_PROFILING_FOR_MOVIE_REQUEST = 'LOAD_CONSUMER_PROFILING_FOR_MOVIE_REQUEST';
export const LOAD_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS = 'LOAD_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS';
export const LOAD_CONSUMER_PROFILING_FOR_MOVIE_FAILURE = 'LOAD_CONSUMER_PROFILING_FOR_MOVIE_FAILURE';

type LoadConsumerProfilingForMovieRequest = {
  type: typeof LOAD_CONSUMER_PROFILING_FOR_MOVIE_REQUEST,
};

const loadConsumerProfilingForMovieRequest = (): loadConsumerProfilingForMovieRequest => {
  return {
    type: LOAD_CONSUMER_PROFILING_FOR_MOVIE_REQUEST,
  };
};

type LoadConsumerProfilingForMovieSuccess = {
  type: typeof LOAD_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadConsumerProfilingForMovieSuccess = (consumer_profiles: Object): loadConsumerProfilingForMovieSuccess => {
  return {
    type: LOAD_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS,
    payload: consumer_profiles, //sort ascending by title before returning
  };
};

type LoadConsumerProfilingForMovieFailure = {
  type: typeof LOAD_CONSUMER_PROFILING_FOR_MOVIE_FAILURE,
  error: string,
};

const loadConsumerProfilingForMovieFailure = (error: string): loadConsumerProfilingForMovieFailure => {
  return {
    type: LOAD_CONSUMER_PROFILING_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ConsumerProfilingForMovieActions =
  | LoadConsumerProfilingForMovieRequest
  | LoadConsumerProfilingForMovieSuccess
  | LoadConsumerProfilingForMovieFailure;

export const loadConsumerProfilingForMovie = (movieId: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadConsumerProfilingForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/consumer-profiling`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadConsumerProfilingForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadConsumerProfilingForMovieFailure(error));
    return error;
  }
};
