// @flow

export function dataToAudienceByWeekFormat(data) {
  if (data.length === 0) {
    return [];
  }
  var convertedData = [];
  var weekData = data.filter( x => x.name === 'week')[0];
  weekData.data.forEach(dataLine => {
    convertedData.push({"week": dataLine.week, "week_start_date":dataLine.week_start_date,"audience" : dataLine.audience});
  });
  return convertedData;
}

export function dataToAudienceByWeekStackedFormat(data) {
  if (data.length === 0) {
    return [];
  }
  var tempData = [];
  var convertedData = [];
  var weekendData = data.filter( x => x.name === 'w/e')[0];
  var weekData = data.filter( x => x.name === 'week')[0];
  //Add Weekend Data
  weekendData.data.forEach(dataLine => {
    tempData.push({"week_number": dataLine.week_start_date, "weekend" : dataLine.audience});
  });
  tempData.forEach(dataLine => {
    var currentWeek = weekData.data.filter(x => x.week_start_date === dataLine.week_number)
    var weekAudience = currentWeek.length && currentWeek[0].audience;
    convertedData.push({"week_number": dataLine.week_number, "week" : parseInt(weekAudience) - parseInt(dataLine.weekend), "weekend": dataLine.weekend});
  });
  return convertedData;
}


export function ftaTVConsumptionForBarChartFormat(data) {
  if (data.length === 0) {
    return [];
  }
  var convertedData = [];
  var ftaTVObject = {};
  data.forEach(dataLine => {
    ftaTVObject[dataLine.consumption] = dataLine.ratio
  });
  convertedData.push(ftaTVObject);
  return convertedData;
}

export function audienceByGenreReachFormat(data) {
  if (data.length === 0) {
    return [];
  }
  var convertedData = [];
  var otherName = "Other";
  var otherValue = 0.0;

  var totalAudience = 0;
  for (var i = 0; i < data.length; i++) {
    //loop through the array
    totalAudience += data[i].audience; //Do the math!
  }
  data.forEach(dataLine => {
    if(parseFloat(dataLine.audience/totalAudience * 100) <= 2){
      otherValue += parseFloat(dataLine.audience);
    }
    else{
      convertedData.push({"name": dataLine.genre,"value": dataLine.audience});      
    }
  });
  if(otherValue > 0){
    convertedData.push({"name": otherName,"value": otherValue});          
  }
  return convertedData;
}


export function audienceByGenreIndexFormat(data) {
  if (data.length === 0) {
    return [];
  }
  var convertedData = [];
  data.forEach(dataLine => {
    convertedData.push({"name": dataLine.genre.replace(" FILMS", ""),"index": dataLine.index,"index_value": dataLine.index - 100 });
  });
  return convertedData;
}

export function totalAudienceForMovieFormat(data) {
  if (data.length === 0) {
    return [];
  }

  var convertedData = [];

  data.forEach(dataLine => {
    if (dataLine.name === "w/e") {
      dataLine.name = "Weekend";
    }

    dataLine.data = dataLine.data.filter((value, index) => {
      return index <= 11;
    })

    convertedData.push(dataLine);
  })
  return convertedData;
}

/**
 * Selects the nearest Thursday in the past.
 *
 * If today is Thurs - return today.
 * If today is Fri or Sat - return last Thurs.
 * If today is Sun, Mon, Tues, Weds - return last weeks Thurs.
 *
 * Default - return Today.
 */
export function goToThursday(): Date {
  let today = new Date();
  const dayNumber: number = today.getDay();

  if ( dayNumber === 4) {
    return today;
  } else if (dayNumber > 4 ) {
    const toThursday = dayNumber - 4;
    today.setDate(today.getDate() - toThursday);
    return today;
  } else if (dayNumber < 4) {
    const toThursday = dayNumber + 3;
    today.setDate(today.getDate() - toThursday);
    return today;
  }

  return today;
}
