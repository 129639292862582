// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../../types';

export const LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_REQUEST = 'LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_REQUEST';
export const LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_SUCCESS = 'LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_SUCCESS';
export const LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_FAILURE = 'LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_FAILURE';

type LoadAggregatedIndexStatForCampaignRequest = {
  type: typeof LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_REQUEST,
};

const loadAggregatedIndexStatForCampaignRequest = (): loadAggregatedIndexStatForCampaignRequest => {
  return {
    type: LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_REQUEST,
  };
};

type LoadAggregatedIndexStatForCampaignSuccess = {
  type: typeof LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_SUCCESS,
  payload: Object,
};

const loadAggregatedIndexStatForCampaignSuccess = (audience: Object): loadAggregatedIndexStatForCampaignSuccess => {
  return {
    type: LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_SUCCESS,
    payload: audience,
  };
};

type LoadAggregatedIndexStatForCampaignFailure = {
  type: typeof LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_FAILURE,
  error: string,
};

const loadAggregatedIndexStatForCampaignFailure = (error: string): loadAggregatedIndexStatForCampaignFailure => {
  return {
    type: LOAD_AGGREGATED_INDEX_STAT_FOR_CAMPAIGN_FAILURE,
    error,
  };
};

export type AggregatedIndexStatForCampaignActions =
  | LoadAggregatedIndexStatForCampaignRequest
  | LoadAggregatedIndexStatForCampaignSuccess
  | LoadAggregatedIndexStatForCampaignFailure;

export const loadAggregatedIndexStatForCampaign = (campaignId: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAggregatedIndexStatForCampaignRequest());

    var filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/campaign/${campaignId}/aggregated-campaign-index${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAggregatedIndexStatForCampaignSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAggregatedIndexStatForCampaignFailure(error));
    return error;
  }
};
