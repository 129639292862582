// @flow
import * as actions from '../../actions/cinemaAudience/audienceByGenreIndexByDate';

import type { AudienceByGenreIndexByDateActions } from '../../actions/cinemaAudience/audienceByGenreIndexByDate';

type State = {
  isFetching: boolean,
  audienceByGenreIndexByDateResults: Array<any>,
  message: String
};

const intialState = {
  isFetching: false,
  audienceByGenreIndexByDateResults: [],
  message: ""
};

export const audienceByGenreIndexByDate = (
  state: State = intialState,
  action: AudienceByGenreIndexByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByGenreIndexByDateResults: [],
        message: ""
      }
    case actions.LOAD_AUDIENCE_BY_GENRE_INDEX_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_GENRE_INDEX_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_GENRE_INDEX_BY_DATE_SUCCESS:
      return {
        ...state,
        audienceByGenreIndexByDateResults: action.payload.audience_by_genre_index,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
