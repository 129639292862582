// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_PRODUCT_DATA_FOR_MOVIE_REQUEST = 'LOAD_PRODUCT_DATA_FOR_MOVIE_REQUEST';
export const LOAD_PRODUCT_DATA_FOR_MOVIE_SUCCESS = 'LOAD_PRODUCT_DATA_FOR_MOVIE_SUCCESS';
export const LOAD_PRODUCT_DATA_FOR_MOVIE_FAILURE = 'LOAD_PRODUCT_DATA_FOR_MOVIE_FAILURE';

type LoadProductDataForMovieRequest = {
  type: typeof LOAD_PRODUCT_DATA_FOR_MOVIE_REQUEST,
};

const loadProductDataForMovieRequest = (): loadProductDataForMovieRequest => {
  return {
    type: LOAD_PRODUCT_DATA_FOR_MOVIE_REQUEST,
  };
};

type LoadProductDataForMovieSuccess = {
  type: typeof LOAD_PRODUCT_DATA_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadProductDataForMovieSuccess = (product_data: Object): loadProductDataForMovieSuccess => {
  return {
    type: LOAD_PRODUCT_DATA_FOR_MOVIE_SUCCESS,
    payload: product_data, //sort ascending by title before returning
  };
};

type LoadProductDataForMovieFailure = {
  type: typeof LOAD_PRODUCT_DATA_FOR_MOVIE_FAILURE,
  error: string,
};

const loadProductDataForMovieFailure = (error: string): loadProductDataForMovieFailure => {
  return {
    type: LOAD_PRODUCT_DATA_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ProductDataForMovieActions =
  | LoadProductDataForMovieRequest
  | LoadProductDataForMovieSuccess
  | LoadProductDataForMovieFailure;

export const loadProductDataForMovie = (movieId: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadProductDataForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/product-data`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadProductDataForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadProductDataForMovieFailure(error));
    return error;
  }
};
