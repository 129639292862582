// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_REQUEST = 'LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_REQUEST';
export const LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_SUCCESS = 'LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_SUCCESS';
export const LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_FAILURE = 'LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_FAILURE';

type LoadAudienceByGenreReachByDateRequest = {
  type: typeof LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_REQUEST,
};

const loadAudienceByGenreReachByDateRequest = (): loadAudienceByGenreReachByDateRequest => {
  return {
    type: LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_REQUEST,
  };
};

type LoadAudienceByGenreReachByDateSuccess = {
  type: typeof LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_SUCCESS,
  payload: Object,
};

const loadAudienceByGenreReachByDateSuccess = (audience_by_markets: Object): loadAudienceByGenreReachByDateSuccess => {
  return {
    type: LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_SUCCESS,
    payload: audience_by_markets, //sort ascending by title before returning
  };
};

type LoadAudienceByGenreReachByDateFailure = {
  type: typeof LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_FAILURE,
  error: string,
};

const loadAudienceByGenreReachByDateFailure = (error: string): loadAudienceByGenreReachByDateFailure => {
  return {
    type: LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_FAILURE,
    error,
  };
};

export type AudienceByGenreReachByDateActions =
  | LoadAudienceByGenreReachByDateRequest
  | LoadAudienceByGenreReachByDateSuccess
  | LoadAudienceByGenreReachByDateFailure;

export const loadAudienceByGenreReachByDate = (date_from: string, date_to: string, filter: string): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAudienceByGenreReachByDateRequest());
    var filterIds = "";
    if(filter.length > 0){
      filterIds = "&filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/date/audience-by-genre-reach?date_from=${date_from}&date_to=${date_to}${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAudienceByGenreReachByDateSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAudienceByGenreReachByDateFailure(error));
    return error;
  }
};
