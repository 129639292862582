// @flow
import * as actions from '../../actions/cinemaAudience/audienceByGenreReachByDate';

import type { AudienceByGenreReachByDateActions } from '../../actions/cinemaAudience/audienceByGenreReachByDate';

type State = {
  isFetching: boolean,
  audienceByGenreReachByDateResults: Array<any>,
  message: String
};

const intialState = {
  isFetching: false,
  audienceByGenreReachByDateResults: [],
  message: ''
};

export const audienceByGenreReachByDate = (
  state: State = intialState,
  action: AudienceByGenreReachByDateActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        audienceByGenreReachByDateResults: []
      }
    case actions.LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_AUDIENCE_BY_GENRE_REACH_BY_DATE_SUCCESS:
      return {
        ...state,
        audienceByGenreReachByDateResults: action.payload.audience_by_genre_reach,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
