// @flow
import * as actions from '../../actions/movieByAudience/ftaTvConsumption';

import type { FtaTvConsumptionActions } from '../../actions/movieByAudience/ftaTvConsumption';

type State = {
  isFetching: boolean,
  ftaTvConsumptionForMovieResults: Array<any>,
  message: String,
};

const intialState = {
  isFetching: false,
  ftaTvConsumptionForMovieResults: [],
  message: "",
};

export const ftaTvConsumption = (
  state: State = intialState,
  action: FtaTvConsumptionActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_BY_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        ftaTvConsumptionForMovieResults: [],
        message: ''
      }
    case actions.LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        ftaTvConsumptionForMovieResults: action.payload.fta_tv_consumption,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
