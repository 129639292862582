// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../middleware';
import { getBaseUrl,sortByKey } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_RATINGS_REQUEST = 'LOAD_RATINGS_REQUEST';
export const LOAD_RATINGS_SUCCESS = 'LOAD_RATINGS_SUCCESS';
export const LOAD_RATINGS_FAILURE = 'LOAD_RATINGS_FAILURE';

type LoadRatingsRequest = {
  type: typeof LOAD_RATINGS_REQUEST,
};

const loadRatingsRequest = (): loadRatingsRequest => {
  return {
    type: LOAD_RATINGS_REQUEST,
  };
};

type LoadRatingsSuccess = {
  type: typeof LOAD_RATINGS_SUCCESS,
  payload: Object,
};

const loadRatingsSuccess = (ratings: Object): loadRatingsSuccess => {
  return {
    type: LOAD_RATINGS_SUCCESS,
    payload: sortByKey(ratings, 'ratingDesc'), //sort ascending by title before returning
  };
};

type LoadRatingsFailure = {
  type: typeof LOAD_RATINGS_FAILURE,
  error: string,
};

const loadRatingsFailure = (error: string): loadRatingsFailure => {
  return {
    type: LOAD_RATINGS_FAILURE,
    error,
  };
};

export type AnnouncementsActions =
  | LoadRatingsRequest
  | LoadRatingsSuccess
  | LoadRatingsFailure;

export const loadRatings = (): ThunkwithAction<MoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadRatingsRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/ratings`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadRatingsSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadRatingsFailure(error));
    return error;
  }
};
