// @flow
import * as actions from '../../actions/genreByAudience/uniqueAudienceStatByDateAndGenre';

import type { UniqueAudienceStatByDateAndGenreActions } from '../../actions/genreByAudience/uniqueAudienceStatByDateAndGenre';

type State = {
  isFetching: boolean,
  uniqueAudienceStatByDateAndGenreResults: string,
  message: string,
};

const intialState = {
  isFetching: false,
  uniqueAudienceStatByDateAndGenreResults: "",
  message: "",
};

export const uniqueAudienceStatByDateAndGenre = (
  state: State = intialState,
  action: UniqueAudienceStatByDateAndGenreActions
) => {
  switch (action.type) {
    case 'RESET_CINEMA_AUDIENCE_DATA':
      return {
        ...state,
        isFetching: false,
        uniqueAudienceStatByDateAndGenreResults: "",
        message: ""
      }
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_UNIQUE_AUDIENCE_STAT_BY_DATE_AND_GENRE_SUCCESS:
      return {
        ...state,
        uniqueAudienceStatByDateAndGenreResults: action.payload.unique_cinema_delivery,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
